App.ChainStoreReceipts = class ChainStoreReceipts extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.ChainStoreReceiptIndex();
  }

  new() {
    new App.ChainStoreReceiptForm();
  }

  edit() {
    new App.ChainStoreReceiptForm();
  }
}

App.ChainStoreReceiptIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
    $(document).on('click', '#export_yayoi_hanbai', this.onClickExportYayoiHanbai.bind(this));
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }

  onClickExportYayoiHanbai(event) {
    App.Sessions.downloadWithLoading(ROOT_PATH + `./chain_store_receipts/export_yayoi_csv.csv?${$('#search_condition_copy_form').serialize()}`, 'processState', disableAllControl, enableAllControl);
  }
}

App.ChainStoreReceiptForm = class {
  constructor () {
    new App.ChainStoreReceiptDetailTable();
    new App.SearchModalBase('customer');
    new App.SearchModalBase('sale');
    new App.NestedModal('customer', '#search_customer_modal_button', '#search_customer_modal_close', '#searchCustomerModal');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerNestedModal);
    $(document).on('customer:select', '#chain_store_receipt_customer_name', this.onSelectCustomerModal);
    $(document).on('sale:select', '#chain_store_receipt_sale_id', this.onSelectSaleModal);
  }

  onSelectCustomerNestedModal(event, select_data) {
    $('#searchSaleModal #customer_name').val(select_data.name);
  }

  onSelectCustomerModal(event, select_data) {
    $('#chain_store_receipt_customer_id').val(select_data.id);
    $('#chain_store_receipt_customer_code').val(select_data.code);
    $('#chain_store_receipt_customer_name').val(select_data.name);
  }

  onSelectSaleModal(event, select_data) {
    $('#chain_store_receipt_sale_id').val(select_data.id);
    $('#chain_store_receipt_sale_slip_number').val(select_data.slip_number);
  }
}
