App.Sales = class Sales extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.SaleIndex();
  }

  new() {
    new App.SaleForm();
  }

  edit() {
    new App.SaleForm();
  }
}

App.SaleIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
    $(document).on('click', '#export_yayoi_hanbai', this.onClickExportYayoiHanbai.bind(this));
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }

  onClickExportYayoiHanbai(event) {
    App.Sessions.downloadWithLoading(ROOT_PATH + `./sales/export_yayoi_csv.csv?${$('#search_condition_copy_form').serialize()}`, 'processState', disableAllControl, enableAllControl);
  }
}

App.SaleForm = class {
  constructor () {
    new App.SaleDetailTable();
    new App.SearchModalBase('customer');
    new App.SearchModalBase('estimate');
    new App.NestedModal('customer', '#search_customer_modal_button', '#search_customer_modal_close', '#searchCustomerModal');
    $(document).on('click', '#export_report', this.onClickExport);
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerNestedModal);
    $(document).on('customer:select', '#sale_customer_name', this.onSelectCustomerModal);
    $(document).on('estimate:select', '#sale_estimate_id', this.onSelectEstimateModal);
  }

  onClickExport(event) {
    if (App.InputCommon.existUnsaved()) {
      alert(I18n.sale.message.export_before_save);
    } else {
      // 「このサイトを離れますか?」を非表示にする。フォームの内容を変更後、submit前にajaxでGETリクエストなどを実行していると表示されるかもしれない
      $(window).on('beforeunload', (e) => e.preventDefault());
      $(window).off('beforeunload');
      App.Sessions.downloadWithLoading(ROOT_PATH + `./sales/${$('#sale_id').val()}.pdf?company_seal=${$('#company_seal').prop('checked')}`, 'processState', disableAllControl, enableAllControl);
    }
  }

  onSelectCustomerNestedModal(event, select_data) {
    $('#searchEstimateModal #customer_name').val(select_data.name);
  }

  onSelectCustomerModal(event, select_data) {
    $('#sale_customer_id').val(select_data.id);
    $('#sale_customer_code').val(select_data.code);
    $('#sale_customer_name').val(select_data.name);
    $('#sale_transaction_type').val(select_data.transaction_type);
  }

  onSelectEstimateModal(event, select_data) {
    $('#sale_estimate_id').val(select_data.id);
    $('#sale_estimate_slip_number').val(select_data.slip_number);
  }
}
