App.EstimateAccumulatingTable = class {
  constructor () {
    new App.EstimateItemSearchModal();
    const selector = ['input', 'select'].map((string) => `.estimate_accumulating_table tbody tr:visible:last ${string}`).join(',')
    $(document).on('change', selector, () => $('#estimate_accumulatings_add_link').click());
    $(document).on('nested:fieldAdded:estimate_accumulatings', this.onAddedRow);
    $(document).on('nested:fieldRemoved:estimate_accumulatings', this.onRemovedRow.bind(this));
    $(document).on('change', 'select.estimate_accumulating_room_type', this.onChangeRoomType);
    $(document).on('change', '.item_weight, .item_volume, #estimate_accumulatings .quantity', this.onChangeItem.bind(this));
    $(document).on('change', '#add_condition_coefficient', this.onChangeAddConditionCoefficient.bind(this));
    $(document).on('click', '.estimate_accumulating_table .rank-up', App.TableCommon.onRankUp);
    $(document).on('click', '.estimate_accumulating_table .rank-down', App.TableCommon.onRankDown);
    $(document).on('click', '#copy_detail', this.onClickCopyDetail);
  }

  onAddedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).find('input.display_order').val($('.estimate_accumulating_table tbody tr').length);
  }

  onRemovedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).insertAfter('#estimate_accumulatings');
    this.reflectTotal();
  }

  onChangeRoomType (event) {
    const room_name = $(event.target).parents('tr').find('.estimate_accumulating_room_name');
    if (!room_name.val()) {
      room_name.val($(event.target).find('option:selected').text());
    }
  }

  onChangeItem(event) {
    const row = $(event.target).parents('tr');
    recoverComma2(row.find('.weight_subtotal').val(parseFloatValue(removeCommaValue(row.find('.item_weight').val())) * parseIntegerValue(removeCommaValue(row.find('.quantity').val()))), 2);
    recoverComma2(row.find('.volume_subtotal').val(parseFloatValue(removeCommaValue(row.find('.item_volume').val())) * parseIntegerValue(removeCommaValue(row.find('.quantity').val()))), 2);
    this.reflectTotal();
  }

  onChangeAddConditionCoefficient(event) {
    this.reflectTotal();
  }

  reflectTotal() {
    const total_item_volume = $('#estimate_accumulatings tr:visible .volume_subtotal').get().map((e) => parseFloat($(e).val())).filter(Boolean).reduce((result, value) => result + value, 0);
    $('#total_item_volume').val(total_item_volume).change();
    recoverComma2($('#total_item_volume'), 2);

    const estimate_unit_price_amount = removeCommaValue($('#estimate_unit_price').val()) * removeCommaValue($('#add_condition_coefficient').val());
    recoverComma($('#estimate_unit_price_amount').val(estimate_unit_price_amount));
    recoverComma($('#estimate_unit_price_amount_copy').val(estimate_unit_price_amount));

    const accumulating_amount = removeCommaValue($('#total_item_volume').val()) * removeCommaValue($('#estimate_unit_price_amount').val())
    recoverComma($('#accumulating_amount').val(accumulating_amount));
  }

  onClickCopyDetail(event) {
    const row = $('.estimate_detail_table tbody tr:visible:last');
    row.find('.estimate_estimate_details_name input').val($('#copy_title').val());
    row.find('.quantity input').val($('#total_item_volume').val());
    row.find('.unit select').val('unit_cubic_meter');
    row.find('.unit_price input').val($('#estimate_unit_price_amount').val());
    row.find('.amount input').val($('#accumulating_amount').val()).change();
    if ($('[name=copy_type]:checked').val() === 'copy_type_all') {
      $('.estimate_accumulating_table tbody tr:visible:not(:last)').each(function() {
        const row = $('.estimate_detail_table tbody tr:visible:last');
        row.find('select.estimate_detail_type').val('estimate_detail_type_supplement').change();
        row.find('.estimate_estimate_details_name input').val($(this).find('.name').val()).addClass('ps-4');
        row.find('.quantity input').val($(this).find('.volume_subtotal').val());
        row.find('.unit select').val('unit_cubic_meter');
        row.find('.remarks input').val($(this).find('remarks'));
      })
    }
  }
}
