App.SaleDetailTable = class {
  constructor () {
    const selector = ['input', 'select'].map((string) => `.sale_detail_table tbody tr:visible:last ${string}`).join(',')
    $(document).on('change', selector, () => $('#sale_details_add_link').click());
    $(document).on('change', '.sale_detail_table tbody tr:visible select.sale_detail_type', this.onChangeSaleDetailType);
    $(document).on('nested:fieldAdded:sale_details', this.onAddedRow);
    $(document).on('nested:fieldRemoved:sale_details', this.onRemovedRow);
    $(document).on('change', '.unit_price input, .quantity input', this.onChangePrice);
    $(document).on('change', '.amount input', this.onChangeAmount);
    $(document).on('click', '.sale_detail_table .rank-up', App.TableCommon.onRankUp);
    $(document).on('click', '.sale_detail_table .rank-down', App.TableCommon.onRankDown);
  }

  onChangeSaleDetailType(event) {
    const row = $(event.target).parents('tr');
    if ($(event.target).val() === 'sale_detail_type_supplement') {
      row.find('.name input').addClass('ps-4')
      row.find('.unit_price input').val('').prop('readonly', true);
      row.find('.amount input').val('').prop('readonly', true).change();
    } else {
      row.find('.name input').removeClass('ps-4')
      row.find('.unit_price input').prop('readonly', false);
      row.find('.amount input').prop('readonly', false);
    }
  }

  onAddedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).find('input.display_order').val($('.sale_detail_table tbody tr').length);
  }

  onRemovedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).insertAfter('#sale_details');
  }

  onChangePrice (event) {
    const row = $(event.target).parents('tr');
    row.find('.amount input').val(
      parseIntegerValue(removeCommaValue(row.find('.unit_price input').val())) *
      parseIntegerValue(removeCommaValue(row.find('.quantity input').val()))
    ).change();
  }

  onChangeAmount (event) {
    App.TableCommon.calcGrandTotalWithGrossMargin('.sale_detail_table', 'sale_detail', '#sale_sale_date');
  }
}
