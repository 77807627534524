App.CustomerSales = class CustomerSales extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.CustomerSaleIndex();
  }
}

App.CustomerSaleIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
    $(document).on('click', '#export_csv', this.onClickExportCsv);
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }

  onClickExportCsv(event) {
    App.Sessions.downloadWithLoading(ROOT_PATH + `./customer_sales.csv?${$('#search_condition_copy_form').serialize()}`, 'processState', disableAllControl, enableAllControl);
  }
}
