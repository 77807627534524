App.ChainStoreReceiptDetailTable = class {
  constructor () {
    new App.EstimateItemSearchModal();
    $(document).on('change', 'tr:visible:last input, tr:visible:last select', () => $('#chain_store_receipt_details_add_link').click());
    $(document).on('nested:fieldAdded:chain_store_receipt_details', this.onAddedRow.bind(this));
    $(document).on('nested:fieldRemoved:chain_store_receipt_details', this.onRemovedRow.bind(this));
    $(document).on('change', '.amount input', this.onChangeAmount);
    $(document).on('click', '.chain_store_receipt_detail_table .rank-up', this.onRankUp.bind(this));
    $(document).on('click', '.chain_store_receipt_detail_table .rank-down', this.onRankDown.bind(this));
  }

  onAddedRow (event) {
    App.TableCommon.onChangeRow(event);
    this.reassignRowNumber();
  }

  onRemovedRow (event) {
    App.TableCommon.onChangeRow(event);
    this.reassignRowNumber();
    $(event.target).insertAfter('#chain_store_receipt_details');
  }

  reassignRowNumber () {
    let count = 0;
    $('.chain_store_receipt_detail_table tbody tr:visible').each(function() {
      count = count + 1;
      $(this).find("td.row_number").text(count);
    });
  }

  onChangeAmount (event) {
    const total_amount = $('.chain_store_receipt_detail_table tr:visible td.amount input').get().map((input) => parseIntegerValue($(input).val())).reduce((a, b) => a + b, 0);
    $('#total_amount').val(formatCommaValue(String(total_amount)));
  }

  onRankUp (event) {
    App.TableCommon.onRankUp(event);
    this.reassignRowNumber();
  }

  onRankDown (event) {
    App.TableCommon.onRankDown(event);
    this.reassignRowNumber();
  }
}
