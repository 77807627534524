App.EstimateAddConditionTable = class {
  constructor () {
    new App.TableSearchModalBase('add_condition');
    $(document).on('add_condition:select', '.estimate_add_condition_table .add_condition_id', this.onSelectAddConditionModal.bind(this));
    $(document).on('nested:fieldRemoved:estimate_add_conditions', this.onRemovedRow.bind(this));
    $(document).on('change', '.estimate_add_condition_table tbody tr:visible:last .add_condition_id', () => $('#estimate_add_conditions_add_link').click());
  }

  onSelectAddConditionModal(event, select_data) {
    const row = $(event.target).parents('tr');
    row.find('.add_condition_id').val(select_data.id).change();
    row.find('.name').val(select_data.name);
    recoverComma2(row.find('.coefficient').val(select_data.coefficient), 2);
    this.reflectTotal();
  }

  onRemovedRow(event) {
    this.reflectTotal();
  }

  reflectTotal() {
    const total_coefficient = $('#estimate_add_conditions tr:visible .coefficient').get().map((e) => parseFloat($(e).val())).filter(Boolean).reduce((result, value) => result + (value - 1.00), 1.00);
    $('#add_condition_coefficient').val(total_coefficient).change();
    recoverComma2($('#add_condition_coefficient'), 2);
  }
}
