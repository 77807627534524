App.Receivables = class Receivables extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.ReceivableIndex();
  }
}

App.ReceivableIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
    $(document).on('click', '.month_left_arrow', this.onClickMonthLeftArrow.bind(this));
    $(document).on('click', '.month_right_arrow', this.onClickMonthRightArrow.bind(this));
  }

  onClickMonthLeftArrow(event) {
    $('#bill_month').val(App.CalcCommon.prevMonth($('#bill_month').val()));
    $('#search_btn').click();
  }

  onClickMonthRightArrow(event) {
    $('#bill_month').val(App.CalcCommon.nextMonth($('#bill_month').val()));
    $('#search_btn').click();
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }
}
