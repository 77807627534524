window.App || (window.App = {});

App.Base = class {

  constructor(options = {}) {
    RailsScript.setClearEventHandlers();
    this.enablePopover();
    registerDatepickerEventHandler();
    $(document).off('ajax:send', 'form'); // イベントハンドラが2重で登録されることがあるのでoff
    $(document).on('ajax:send', 'form', showLoading);
    $(document).on('ajax:complete', 'form', hideLoading);
    return this;
  }


  /*
  Run the new action for the create action.  Generally the create action will 'render :new' if there was a problem.
  This prevents doubling the code for each action.
  */
  create() {
    if (typeof $this.new === 'function') {
      return $this.new();
    }
  }


  /*
  Run the edit action for the update action.  Generally the update action will 'render :edit' if there was a problem.
  This prevents doubling the code for each action.
  */
  update() {
    if (typeof $this.edit === 'function') {
      return $this.edit();
    }
  }


  enablePopover() {
    $.fn.popover.Constructor.Default.allowList.button = ['type', 'id', 'class']
    return $('[data-bs-toggle="popover"]').popover();
  }
};
