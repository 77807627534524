App.BulletinBoards = class BulletinBoards extends App.Base {
  constructor() {
    super();
  }

  index () {
    this.file_download();
  }
  
  // ニューリリース一覧
  new_release_list() {
    this.file_download();
  }

  // 添付ファイルのダウンロード
  file_download() {
    $(document).on('click', 'a#attachment_output', this.onClickFileDownload.bind(this));
  }


  onClickFileDownload(event) {
    App.Sessions.downloadWithWait($(event.currentTarget).parents('td').data('fileUrl'), 'processState', disableAllControl, enableAllControl);
    return false;
  }
}