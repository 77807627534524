App.MonthSales = class MonthSales extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.MonthSaleIndex();
  }
}

App.MonthSaleIndex = class {
  constructor () {
    $(document).on('click', '#export_csv', this.onClickExportCsv);
  }

  onClickExportCsv(event) {
    App.Sessions.downloadWithLoading(ROOT_PATH + `./month_sales.csv?${$('#search_condition_copy_form').serialize()}`, 'processState', disableAllControl, enableAllControl);
  }
}
