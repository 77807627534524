// datepicker の設定
const setDatePicker = function(selector, options = {}) {
  let _input = undefined;

  const showAdditionalButton = function(input) {
    setTimeout((function() {
      const buttonPanel = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');
      const btn = $(`<button class='ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all ui-datepicker-clear' type='button'>${I18n.helpers.button.clear}</button>`);
      btn.unbind('click').bind('click', function() {
        $.datepicker._clearDate(_input);
      });
      if (buttonPanel.find('.ui-datepicker-clear').length === 0) {
        btn.appendTo(buttonPanel);
      }
    }), 1);
  };

  // datepickerの今日ボタンをオーバーライド
  $.datepicker._gotoToday = function(id) {
    const inst = this._getInst($(id)[0]);
    this._setDate(inst, new Date());
    this._hideDatepicker();
    $(id).trigger('datepicker:select');
  };

  // 動的に追加されるフォームでは class に datepicker を指定してもカレンダーが表示されない
  // したがって明示的に設定～表示を実行している
  $(selector).datepicker(
    $.extend(
      {
        dateFormat: 'yy/mm/dd',
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        onChangeMonthYear: showAdditionalButton,
        beforeShow(input, inst) {
          _input = input;
          showAdditionalButton(input);
        },
        onSelect(dateText, inst) {
          $(this).trigger('datepicker:select', dateText);
        }
      },
      options
    )
  );

  $(selector).attr('autocomplete', 'off');
};

// datepicker(年月選択用) の設定
const setMonthPicker = function(selector, options = {}) {
  let _input = undefined;

  const showAdditionalButton = function(input) {
    setTimeout((function() {
      const buttonPanel = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');
      const btn = $(`<button class='ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all ui-datepicker-clear' type='button'>${I18n.helpers.button.clear}</button>`);
      btn.unbind('click').bind('click', function() {
        $.datepicker._clearDate(_input);
        $(_input).val('');
      });
      if (buttonPanel.find('.ui-datepicker-clear').length === 0) {
        btn.appendTo(buttonPanel);
      }
    }), 1);
  };

  // datepickerの今日ボタンをオーバーライド
  $.datepicker._gotoToday = function(id) {
    const inst = this._getInst($(id)[0]);
    this._setDate(inst, new Date());
    this._hideDatepicker();
    $(id).trigger('datepicker:select');
  };

  // 動的に追加されるフォームでは class に datepicker を指定してもカレンダーが表示されない
  // したがって明示的に設定～表示を実行している
  $(selector).datepicker(
    $.extend(
      {
        dateFormat: 'yy/mm',
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        onChangeMonthYear: showAdditionalButton,
        beforeShow(input, inst) {
          _input = input;
          showAdditionalButton(input);
          const datestr = $(this).val();
          let current_date = new Date;
          if (datestr.length > 6) {
            const year = datestr.substring(0, 4);
            const month = parseInt(datestr.substring(5, 7)) - 1;
            current_date = new Date(year, month, 1);
          }
          $(this).datepicker('option', 'defaultDate', current_date);
          $(this).datepicker('setDate', current_date);
          if (datestr === '') {
            $(this).datepicker('setDate', datestr);
          }
          return undefined;
        },
        onClose(dateText, inst) {
          if (!$(this).hasModify()) {
            const month = $('#ui-datepicker-div .ui-datepicker-month :selected').val();
            const year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();
            $(this).datepicker('setDate', new Date(year, month, 1));
          }
          return inst;
        },
        onSelect(dateText, inst) {
          $(this).trigger('datepicker:select', dateText);
        }
      },
      options
    )
  );

  $(selector).attr('autocomplete', 'off');
};

window.registerDatepickerEventHandler = function(options = {}) {
  // RailsScript を使っている場合、イベントの設定がクリアされてしまう
  // 再度イベントを設定するために、フラグをオフにしている
  $.datepicker.initialized = false;
  setDatePicker('.datepicker:not(.monthpicker)', options);
  setMonthPicker('.datepicker.monthpicker', options);

  // https://github.com/turbolinks/turbolinks/issues/253
  document.addEventListener('turbolinks:before-cache', function() {
    $.datepicker.dpDiv.remove();
    document.querySelectorAll('input.hasDatepicker').forEach((element) =>
      $(element).datepicker('destroy'));
  });

  document.addEventListener('turbolinks:before-render', function(event) {
    if (event.data && event.data.newBody) {
      $.datepicker.dpDiv.appendTo(event.data.newBody);
    }
  });
};
