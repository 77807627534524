window.App || (window.App = {});
App.Sessions = class Sessions extends App.Base {
  constructor() {
    super();
  }


  beforeAction(action) {
    return;
  }


  afterAction(action) {
    return;
  }


  index() {
    return;
  }


  show() {
    return;
  }


  new() {
    return;
  }


  edit() {
    return;
  }

  static downloadWithWait(url, key, start_function, end_function, post_params = null) {

    try {
      // 不可視のコントロールとしてダウンロード用のリンクを作成する
      // (可視のままだと使用不可になってしまい、ダウンロードができなくなるので、不可視に)
      if (post_params) {
        // POST で実行するため FORM の作成
        $("#invisible_donwload_link").remove();
        $("#invisible_donwload_form").remove();
        const form = $('<form />')
                .attr('method', 'POST')
                .attr('action', url)
                .attr('id', 'invisible_donwload_form')
                .addClass('d-none')
                .data('remote', true); // ローディング画面が消えなくなるために追加
        // input の作成
        for (let param of Array.from(post_params)) {
          for (let param_key in param) {
            const param_value = param[param_key];
            let input = $('<input />')
                      .attr('type', 'hidden')
                      .attr('name', param_key)
                      .val(param_value);
            form.append(input);
          }
        }
        // submit ボタンの作成
        let input = $('<input />')
                  .attr('type', 'submit')
                  .attr('id', 'invisible_donwload_link');
        form.append(input);
        form.appendTo("body");
      } else {
        $("#invisible_donwload_link").remove();
        let aref = $('<a />')
                .attr('class', 'invisible')
                .attr('id', 'invisible_donwload_link')
                .attr('data-turbolinks', false);
        //if (isIOS() || warnLeavingUnsaved()) { aref = aref.attr('target', '_blank'); }
        aref.appendTo("body");
      }

      // 画面の制御開始
      App.Sessions.clearStatus(key);

      // ダウンロード開始
      $("#invisible_donwload_link").attr('href', url);
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        $("#invisible_donwload_link")[0].dispatchEvent(e);
      } else {
        $("#invisible_donwload_link")[0].click();
      }
      if (start_function) { start_function(); }
      App.Sessions.waitProcess(key, end_function);
    } catch (error) {
      alert(error);
      if (end_function) { end_function(); }
    }
  }


  static downloadWithLoading(url, key, start_function, end_function, post_params = null) {
    App.Sessions.downloadWithWait(url, key,
      function() {
        if (start_function) { start_function(); }
        showLoading();
      }
      ,
      function() {
        if (end_function) { end_function(); }
        hideLoading();
      }
      , post_params);
  }


  static waitProcess(key, end_function) {
    try {
      $.ajax({
        type: "get",
        url: ROOT_PATH + "./sessions/status/" + key,
        dataType: "json",
        cache: false,
        async: true,
        success(data, textStatus, jqXHR) {
          if (data === 1) {
            // 再試行
            setTimeout((() => App.Sessions.waitProcess(key, end_function)), 500);
          } else {
            if (end_function) { end_function(); }
            App.Sessions.clearStatus(key);
          }
        },
        error(data) {
          console.log('error');
          // iPadの場合、こちらに来てしまう(原因不明)
          // 再試行
          setTimeout((() => App.Sessions.waitProcess(key, end_function)), 500);
        }
      });
    } catch (e) {}
  }

  static clearStatus(key) {
    try {
      $.ajax({
        type: "delete",
        url: ROOT_PATH + "./sessions/status/" + key,
        dataType: "json",
        async: false,
        success(data, textStatus, jqXHR) {},
          //
        error(data) {
          alert('error');
        }
      });
    } catch (e) {}
  }
}
