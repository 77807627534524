App.Estimates = class Estimates extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.EstimateIndex();
  }

  new() {
    new App.EstimateForm();
  }

  edit() {
    new App.EstimateForm();
  }
}

App.EstimateIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }
}

App.EstimateForm = class {
  constructor () {
    new App.EstimateDetailTable();
    new App.EstimateAccumulatingTable();
    new App.EstimateAddConditionTable();
    const cities = new App.Cities();
    $(document).on('click', '#export_estimate', this.onClickExport);
    $(document).on('click', '#zip_search', (event) => { cities.onZipSearch('estimate'); });
    $(document).on('click', '#site_zip_search', this.onSiteZipSearch);
    $(document).on('change', '#estimate_prefecture', (event) => { cities.onfindCity('estimate'); });
    $(document).on('change', '#estimate_site_prefecture', (event) => { cities.onfindCity('estimate_site'); });
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#estimate_customer_name', this.onSelectCustomerModal.bind(this));
    $(document).on('customer:select', '#estimate_introduce_customer_name', this.onSelectIntroduceCustomerModal);
    $(document).on('customer:select', '#estimate_estimate_customer_name', this.onSelectEstimateCustomerModal.bind(this));
    $(document).on('click', '#clear_introduce_customer', this.onClickClearIntroduceCustomer);
    $(document).on('click', '#clear_estimate_customer', this.onClickClearEstimateCustomer);
  }

  onClickExport(event) {
    if (App.InputCommon.existUnsaved()) {
      alert(I18n.estimate.message.export_before_save);
    } else {
      // 「このサイトを離れますか?」を非表示にする。フォームの内容を変更後、submit前にajaxでGETリクエストなどを実行していると表示されるかもしれない
      $(window).on('beforeunload', (e) => e.preventDefault());
      $(window).off('beforeunload');
      App.Sessions.downloadWithLoading(ROOT_PATH + `./estimates/${$('#estimate_id').val()}.pdf?company_seal=${$('#company_seal').prop('checked')}`, 'processState', disableAllControl, enableAllControl);
    }
  }

  onSiteZipSearch(event) {
    AjaxZip3.zip2addr($("#site_zip_search").closest('.input-group').find('input')[0], '', `estimate[site_prefecture]`, `estimate[hidden_city]`, `estimate[site_town]`);
    AjaxZip3.onSuccess = function() {
      // 市区町村のリストを都道府県に合致させる為changeイベントを発火させる
      $(`#estimate_site_prefecture`).trigger('change');
    };
  }

  onSelectCustomerModal(event, select_data) {
    this.setCustomer(select_data);
    this.setEstimateCustomer(select_data);
  }

  // 顧客(請求先)情報
  setCustomer(select_data) {
    $('#estimate_customer_id').val(select_data.id);
    $('#estimate_customer_code').val(select_data.code);
    $('#estimate_customer_name').val(select_data.name);
    $('#estimate_customer_contact_person').val(select_data.contact_person);
    $('#estimate_customer_zip').val(select_data.zip);
    $('#estimate_customer_prefecture').val(select_data.prefecture);
    $('#estimate_customer_city').val(select_data.city);
    $('#estimate_customer_town').val(select_data.town);
    $('#estimate_customer_tel').val(select_data.tel);
    $('#estimate_customer_cellphone_number').val(select_data.cellphone_number);
  }

  // 見積情報
  setEstimateCustomer(select_data) {
    $('#estimate_estimate_customer_id').val(select_data.id);
    $('#estimate_estimate_customer_code').val(select_data.code);
    $('#estimate_estimate_customer_name').val(select_data.name);
    $('#estimate_estimate_customer_contact_person').val(select_data.contact_person);
    $('#estimate_zip').val(select_data.zip);
    $('#estimate_prefecture').val(select_data.prefecture);
    $('#estimate_city').val(select_data.city);
    $('#estimate_town').val(select_data.town);
    $('#estimate_tel').val(select_data.tel);
    $('#estimate_cellphone_number').val(select_data.cellphone_number);
  }

  onSelectIntroduceCustomerModal(event, select_data) {
    $('#estimate_introduce_customer_id').val(select_data.id);
    $('#estimate_introduce_customer_code').val(select_data.code);
    $('#estimate_introduce_customer_name').val(select_data.name);
  }

  onClickClearIntroduceCustomer(event) {
    $('#estimate_introduce_customer_id').val('');
    $('#estimate_introduce_customer_code').val('');
    $('#estimate_introduce_customer_name').val('');
  }

  onClickClearEstimateCustomer(event) {
    $('#estimate_estimate_customer_id').val('');
    $('#estimate_estimate_customer_code').val('');
    $('#estimate_estimate_customer_name').val('');
  }

  onSelectEstimateCustomerModal(event, select_data) {
    this.setEstimateCustomer(select_data);

    if (!$('#estimate_customer_id').val()) {
      this.setCustomer(select_data);
    }
  }
}
