App.ChainStoreBills = class ChainStoreBills extends App.Base {
  constructor() {
    super();
  }

  index() {
    new App.ChainStoreBillIndex();
  }
}

App.ChainStoreBillIndex = class {
  constructor () {
    new App.SearchModalBase('customer');
    $(document).on('customer:select', '#customer_name', this.onSelectCustomerModal);
    $(document).on('click', '.month_left_arrow', this.onClickMonthLeftArrow.bind(this));
    $(document).on('click', '.month_right_arrow', this.onClickMonthRightArrow.bind(this));
    $(document).on('change', '#close_date_input', this.onChangeCloseDate);
    $(document).on('datepicker:select', '#close_date_input', this.onDatePickCloseDate);
    $(document).on('change', '#closing_date', this.onChangeClosingDate);
    $(document).on('click', '#closing', this.onClickClosing);
    $(document).on('click', '#closing_cancel', this.onClickClosingCancel.bind(this));
    $(document).on('click', '#bill_issue', this.onClickBillIssue.bind(this));
  }

  onClickMonthLeftArrow(event) {
    $('#bill_month').val(App.CalcCommon.prevMonth($('#bill_month').val()));
    this.updateCloseDate();
    $('#search_btn').click();
  }

  onClickMonthRightArrow(event) {
    $('#bill_month').val(App.CalcCommon.nextMonth($('#bill_month').val()));
    this.updateCloseDate();
    $('#search_btn').click();
  }

  updateCloseDate() {
    let date = new Date($('#bill_month').val());
    let closing_day = $('#closing_date').val().replace(/closing_date_/, '');
    let month_delta = 0;
    if (closing_day == 'end') {
      closing_day = 0;
      month_delta = 1;
    }
    $('#close_date, #close_date_input').val(formatDate(new Date(date.getFullYear(), date.getMonth() + month_delta, closing_day), "yyyy/MM/dd")).change();
  }

  onSelectCustomerModal(event, select_data) {
    $('#customer_name').val(select_data.name);
  }

  onChangeCloseDate(event) {
    $('#close_date').val($(event.target).val());
  }

  onDatePickCloseDate(event) {
    $('#close_date').val($(event.target).val());
    $('#search_btn').click();
  }

  onChangeClosingDate(event) {
    $('#close_date').val('');
  }

  onClickClosing(event) {
    const customer_ids = $('.chain_store_bill_table').find('input.select-checkbox[type=checkbox]:enabled:visible:checked')
                                                     .get().filter( (checkbox) => $(checkbox).data('status') == 'status_none')
                                                     .map((checkbox) => $(checkbox).data('customer-id'));
    if (customer_ids.length == 0) {
      alert(I18n.common.message.unselected);
      return;
    }
    ajaxWithLoading({
      type: 'POST',
      url: ROOT_PATH + './chain_store_bills/closing',
      dataType: 'json',
      data: {
        bill_month: $('#bill_month').val(),
        close_date: $('#close_date').val(),
        customer_ids: customer_ids,
      }
    }).done(function(data) {
      if (data.alert) {
        alert(data.alert);
      } else {
        $('[name=checked_indexes]').val($('input.select-checkbox:checked').get().map((input) => $(input).parents('tr').data('index')))
        $('#search_condition_submit').click();
      }
    }).fail(data => alert('error'));
  }

  onClickClosingCancel(event) {
    const bill_ids = $('.chain_store_bill_table').find('input.select-checkbox[type=checkbox]:enabled:visible:checked')
                                                 .get().filter( (checkbox) => $(checkbox).data('status') != 'status_none')
                                                 .map((checkbox) => $(checkbox).data('bill-id'));
    if (bill_ids.length == 0) {
      alert(I18n.common.message.unselected);
      return;
    }
    ajaxWithLoading({
      type: 'DELETE',
      url: ROOT_PATH + './chain_store_bills/closing',
      dataType: 'json',
      data: {
        bill_ids: bill_ids,
      }
    }).done(function(data) {
      if (data.alert) {
        alert(data.alert);
      } else {
        $('[name=checked_indexes]').val($('input.select-checkbox:checked').get().map((input) => $(input).parents('tr').data('index')))
        $('#search_condition_submit').click();
      }
    }).fail(data => alert('error'));
  }

  onClickBillIssue (event) {
    const bill_ids = $('.chain_store_bill_table').find('input.select-checkbox[type=checkbox]:enabled:visible:checked')
                                                 .get().filter( (checkbox) => $(checkbox).data('status') != 'status_none')
                                                 .map((checkbox) => $(checkbox).data('bill-id'));
    if (bill_ids.length == 0) {
      alert(I18n.common.message.unselected);
      return;
    }
    App.Sessions.downloadWithLoading(ROOT_PATH + `./chain_store_bills/issue.pdf?bill_month=${$('#bill_month').val()}&bill_ids=${bill_ids}&company_seal=${$('#company_seal').prop('checked')}`, 'processState', disableAllControl, this.onEndBillIssue);
  }

  onEndBillIssue () {
    enableAllControl();
    $('#search_condition_submit').click();
  }
}
