window.App || (window.App = {});
App.Cities = class Cities extends App.Base {
  constructor() {
    super();
  }

  onZipSearch(type) {
    AjaxZip3.zip2addr($("#zip_search").closest('.input-group').find('input')[0], '', `${type}[prefecture]`, `${type}[hidden_city]`, `${type}[town]`);
    AjaxZip3.onSuccess = function() {
      // 市区町村のリストを都道府県に合致させる為changeイベントを発火させる
      $(`#${type}_prefecture`).trigger('change');
    };
  }

  onfindCity(type) {
    const zip_search_city = $("#zip_search_city").val();
    const prefecture_code = $(`select#${type}_prefecture option:selected`).data('prefectureCode');
    that = this;
    $.ajax({
      type: "GET",
      url: ROOT_PATH + "./cities/find_city",
      dataType: "json",
      data: {
        prefecture_code: prefecture_code,
        authenticity_token: $("#authenticity_token").val(),
      }
    })
    .done((data, textStatus, jqXHR) => {
      $(`select#${type}_city option[value!='']`).remove();
      for(const city of data) {
        const option = `<option value=${city}>${city}</option>`;
        $(`select#${type}_city`).append(option);
      }
      if (zip_search_city) {
        $(`select#${type}_city`).val(zip_search_city) 
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      alert('error');
    })
    .always(() => {
    });
  }
}