App.EstimateItemSearchModal = class {
  constructor () {
    new App.TableSearchModalBase('estimate_item');
    $(document).on('estimate_item:select', '.estimate_item_id', this.onSelectModal);
  }

  onSelectModal(event, select_data) {
    const row = $(event.target).parents('tr');
    row.find('.estimate_item_id').val(select_data.id);
    row.find('.name').val(select_data.name);
    row.find('.item_type').val(select_data.item_type);
    row.find('.item_weight').val(select_data.item_weight);
    row.find('.item_volume').val(select_data.item_volume).change();
  }
}
