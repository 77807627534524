// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// src
import "jquery"
import "jquery-ui/ui/widgets/datepicker"
import "jquery-ui/ui/i18n/datepicker-ja"
import "jquery_nested_form"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import accounting from "accounting-js"
window.accounting = accounting
window.accounting.settings.precision = 0
import "hitechs_common"
import "src/base"

// stylesheets
import "../stylesheets/application"

// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

var requireSrc = require.context('src', true, /\.(js)|(erb)$/)
requireSrc.keys().forEach(requireSrc)