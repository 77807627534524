App.Customers = class Customers extends App.Base {
  constructor() {
    super();
  }

  new() {
    new App.CustomerList();
    new App.CustomerForm();
  }

  edit() {
    new App.CustomerList();
    new App.CustomerForm();
  }
}

App.CustomerList = class {
  constructor () {
    $(document).on('change', '#customer_list_buttons .site_filter_char, #display_only_customer, #search_customer_name, #search_tel', this.onChangeCondition);
    $(document).on('click', '#import_yayoi_hanbai', this.onClickImportYayoiHanbai.bind(this));
    $(document).on('change', '#yayoi_hanbai_file', this.onChangeYayoiHanbaiFile.bind(this));
  }

  onChangeCondition (event) {
    $('#customer_list_submit').click();
  }

  onClickImportYayoiHanbai (event) {
    $('#yayoi_hanbai_file').click();
  }

  onChangeYayoiHanbaiFile (event) {
    showLoading();
    $('#yayoi_hanbai_import_form').submit();
  }
}

App.CustomerForm = class {
  constructor () {
    const cities = new App.Cities();
    new App.SearchModalBase('customer');
    $(document).on('click', 'tr.customer_item', this.onClickCustomerItem);
    $(document).on('change', '#customer_customer_type', this.onChangeCustomerType);
    $(document).on('click', '#zip_search', (event) => { cities.onZipSearch('customer'); });
    $(document).on('change', '#customer_prefecture', (event) => { cities.onfindCity('customer'); });
    $(document).on('customer:select', '#customer_bill_customer_name', this.onSelectCustomerModal.bind(this));
    $(document).on('click', '#delete_bill_customer', this.onDeleteBillCustomer.bind(this));
  }

  onClickCustomerItem (event) {
    history.pushState({}, '', ROOT_PATH + `./customers/${$(event.currentTarget).data('id')}/edit`);
  }

  onChangeCustomerType (event) {
    if ($(event.target).val() === 'customer_type_personal') {
      $('.contact_person_wrapper').addClass('d-none');
      $('#customer_contact_person').val('');
      $('#customer_business_type').val('');
    } else {
      $('.contact_person_wrapper').removeClass('d-none');
    }
  } 

  onSelectCustomerModal(event, select_data) {
    if (select_data) {
      this.setBillCustomer(select_data);
    }
  }

  onDeleteBillCustomer(event) {
    this.setBillCustomer({});
  }

  setBillCustomer(data) {
    $('#customer_bill_customer_id').val(data.id);
    $('#customer_bill_customer_code').val(data.code);
    $('#customer_bill_customer_name').val(data.name);
  }
}
