// https://github.com/gemgento/rails_script/blob/v2.0.4/vendor/assets/javascripts/rails_script/init.coffee
window.RailsScript ||= {};
window.App ||= {};
window.Element ||= {};
window.Utility ||= {};

// 以前のコードにおいて typeof Turbolinks !== 'undefined' のような判定をしていたが、
// application.jsで読み込まれるTurbolinksが参照できないためrequire.resolve('turbolinks')でTurbolinksの使用有無を判定する
try {
  require.resolve('turbolinks');
  $(document).on('page:load.rails_script turbolinks:load.rails_script', () => {
    RailsScript.init();
  });
} catch {
  $(function() {
    RailsScript.init();
  });
}

// Initializer
RailsScript.init = function() {
  const controller = $('#rails-script').data('controller');
  const action = $('#rails-script').data('action');
  Utility.RailsVars = $('#rails-script').data('vars');

  window.$this = new (App[controller] || App.Base)();

  if (typeof $this.beforeAction === 'function') {
    $this.beforeAction(action);
  }
  if (typeof $this[action] === 'function') {
    $this[action]();
  }
  if (typeof $this.afterAction === 'function') {
    $this.afterAction(action);
  }
};

// Clear event handlers on navigation
RailsScript.setClearEventHandlers = function() {
  jQuery(document).on('page:before-change turbolinks:before-render', function() {
    for (let element of [window, document]) {
      const object = jQuery._data(element, 'events') || {};
      for (let event in object) {
        const handlers = object[event];
        for (let handler of Array.from(handlers)) {
          if ((handler != null) && (handler.namespace === '')) {
            jQuery(element).off(event, handler.handler);
          }
        }
      }
    }
  });
};