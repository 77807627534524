App.EstimateDetailTable = class {
  constructor () {
    const selector = ['input', 'select'].map((string) => `.estimate_detail_table tbody tr:visible:last ${string}`).join(',')
    $(document).on('change', selector, () => $('#estimate_details_add_link').click());
    $(document).on('change', '.estimate_detail_table tbody tr:visible select.estimate_detail_type', this.onChangeEstimateDetailType);
    $(document).on('change', '.estimate_image_table tbody tr:visible:last input.estimate_image_file', () => $('#estimate_images_add_link').click());
    $(document).on('nested:fieldAdded:estimate_details', this.onAddedRow);
    $(document).on('nested:fieldRemoved:estimate_details', this.onRemovedRow.bind(this));
    $(document).on('change', '.unit_price input, .quantity input', this.onChangePrice);
    $(document).on('change', '.amount input', this.onChangeAmount);
    $(document).on('click', '.estimate_detail_table .rank-up', App.TableCommon.onRankUp);
    $(document).on('click', '.estimate_detail_table .rank-down', App.TableCommon.onRankDown);
  }

  onChangeEstimateDetailType(event) {
    const row = $(event.target).parents('tr');
    if ($(event.target).val() === 'estimate_detail_type_supplement') {
      row.find('.name input').addClass('ps-4')
      row.find('.unit_price input').val('').prop('readonly', true);
      row.find('.amount input').val('').prop('readonly', true).change();
    } else {
      row.find('.name input').removeClass('ps-4')
      row.find('.unit_price input').prop('readonly', false);
      row.find('.amount input').prop('readonly', false);
    }
  }

  onAddedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).find('input.display_order').val($('.estimate_detail_table tbody tr').length);
    recoverComma2($(event.target).find('.quantity input'), 2);
  }

  onRemovedRow (event) {
    App.TableCommon.onChangeRow(event);
    $(event.target).insertAfter('#estimate_details');
    this.onChangeAmount();
  }

  onChangePrice (event) {
    const row = $(event.target).parents('tr');
    row.find('.amount input').val(
      parseIntegerValue(removeCommaValue(row.find('.unit_price input').val())) *
      parseFloatValue(removeCommaValue(row.find('.quantity input').val()))
    ).change();
  }

  onChangeAmount (event) {
    App.TableCommon.calcGrandTotalWithGrossMargin('.estimate_detail_table', 'estimate_detail', '#estimate_estimate_date');
  }
}
