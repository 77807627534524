App.InputCommon = class {
  static onClickDateNext(event) {
    const input = $(event.currentTarget).siblings('input');
    input.val(App.DateCommon.dateFormatJP(App.DateCommon.incrementDate(new Date(input.val()), 1)));
    input.change();
  }

  static onClickDatePrevious(event) {
    const input = $(event.currentTarget).siblings('input');
    input.val(App.DateCommon.dateFormatJP(App.DateCommon.incrementDate(new Date(input.val()), -1)));
    input.change();
  }

  static existUnsaved() {
    return $(UPDATE_CHECK_TARGET_ELEMS).get().some((input) => $(input).data('changed'));
  }
}

App.DateCommon = class {
  static dateFormatJP(date) { 
    var yyyy = date.getFullYear(); // 西暦を取得
    var mm = date.getMonth() + 1;  // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = date.getDate(); // 日を取得
    var w = date.getDay();   // 曜日を取得（数値）
    
    // 月と日が一桁の場合は先頭に0をつける
    if (mm < 10) {
        mm = "0" + mm;
    }
    if (dd < 10) {
        dd = "0" + dd;
    }
    
    // NaNが含まれていると日付に変換できないためチェックする
    var is_nan = false
    if (!is_nan && isNaN(yyyy)) {
      is_nan = true
    } else if (!is_nan && isNaN(mm)) {
      is_nan = true
    } else if  (!is_nan && isNaN(dd)) {
      is_nan = true
    } else if  (!is_nan && isNaN(w)) {
      is_nan = true
    }

    if (!is_nan) {
      // 曜日を数値から文字列に変換するための配列
      week = ["日", "月", "火", "水", "木", "金", "土"];
      return yyyy + "/" + mm + "/" + dd + "(" + week[w] + ")";
    } else {
      ''
    }
  }

  static incrementDate(target, delta) {
    const date = new Date(target);
    date.setDate(date.getDate() + delta);
    return date;
  }
}

App.TableCommon = class TableCommon {
  static isLastRow(target) {
    return target.parents('tr').next('tr').length === 0;
  }

  static lastRow(target) {
    const that = this;
    let last_row = '';
    const order_values = $(target).closest('tbody').find('tr').each(function() {
      if (that.isLastRow($(this))) { return last_row = $(this); }
    });
    return last_row;
  }

  //@maxOrder: (target) =>
  //  order_values = $(target).closest('table').find('input.display_order').map () ->
  //    $(this).val()
  //  Math.max(...order_values.get().concat([0]))

  static onRankUp(event) {
    const target = $(event.target).closest('tr');
    const prev = $(target).prevAll("tr:visible:first");
    if (prev.length === 1) {
      return App.TableCommon.swapRow(prev, target);
    }
  }

  static onRankDown(event) {
    const target = $(event.target).closest('tr');
    const next = $(target).nextAll("tr:visible:first");
    if (next.length === 1) {
      return App.TableCommon.swapRow(target, next);
    }
  }

  static onChangeRow(event) {
    let count = 0;
    $(event.target).closest('tbody').find('tr:visible').each(function() {
      count = count + 1;
      $(this).find("input.display_order").val(count);
    });
  }

  static swapRow(prev_row, next_row) {
    const selector = 'input.display_order';
    // tr の入れ替え
    prev_row.insertAfter(next_row);
    // order の入れ替え
    const temp_order = prev_row.find(selector).val();
    prev_row.find(selector).val(next_row.find(selector).val());
    next_row.find(selector).val(temp_order);
    // 変更があったことにする
    return prev_row.find(selector).data('changed', 'changed');
  }

  static toRowNumber(table) {
    let current_number = 1;
    return $(table).find("tr:visible td.row_number").each(function() {
      $(this).text(current_number);
      return current_number += 1;
    });
  }

  static calcGrandTotalWithGrossMargin(collection_selector, detail_prefix, tax_date_selector) {
    // クロージャー
    const set_gross_margin = (amount_data, cost_data) => {
      const gross_margin = amount_data.total_amount_without_tax - cost_data.total_amount_without_tax;
      $('#gross_margin').val(formatCommaValue(String(gross_margin)));
      $('#total_cost').val(formatCommaValue(String(cost_data.total_amount_without_tax)));
      let gross_margin_rate = "0%";
      if (amount_data.total_amount_without_tax !== 0) {
        gross_margin_rate = String(Math.round((100.0 * gross_margin) / amount_data.total_amount_without_tax)) + "%";
      }
      return $('#gross_margin_rate').val(gross_margin_rate);
    };

    return App.TableCommon.calcGrandTotal(collection_selector, detail_prefix, tax_date_selector, set_gross_margin);
  }

  static calcGrandTotal(collection_selector, detail_prefix, tax_date_selector, set_gross_margin) {
    const amount_rows = [];
    const cost_rows = [];

    $(collection_selector).find(`tr:visible .${detail_prefix}_amount input`).each(function() {
      // 金額データの集積
      let row;
      const amount = $(this).parents('tr').find(`.${detail_prefix}_amount input`).val();
      if (amount) {
        row = {};
        row['tax_type'] = $(this).parents('tr').find(`.${detail_prefix}_tax_type select`).val();
        row['tax_rate_type'] = $(this).parents('tr').find(`.${detail_prefix}_tax_rate_type select`).val();
        row['amount'] = parseIntegerValue(amount);
        amount_rows.push(row);
      }
      // 原価データの集積
      const quantity = $(this).parents('tr').find(`.${detail_prefix}_quantity input`).val();
      const cost_price = $(this).parents('tr').find(`.${detail_prefix}_cost_price input`).val();
      const cost_amount = parseIntegerValue(cost_price) * parseIntegerValue(quantity);
      if (cost_amount) {
        row = {};
        row['tax_type'] = $(this).parents('tr').find(`.${detail_prefix}_tax_type select`).val();
        row['tax_rate_type'] = $(this).parents('tr').find(`.${detail_prefix}_tax_rate_type select`).val();
        row['amount'] = parseIntegerValue(cost_amount);
        return cost_rows.push(row);
      }
    });

    // 金額の税率計算
    return ajaxWithLoading({
      type: 'GET',
      url: ROOT_PATH + './tax_rates/calc_total',
      dataType: 'json',
      data: {
        rows: amount_rows,
        date: $(tax_date_selector).val()
      }
    }).done(function(data) {
      // 合計（税抜）、消費税、合計（税込）
      $('#total_amount_without_tax').val(formatCommaValue(String(data.total_amount_without_tax)));
      $('#total_tax').val(formatCommaValue(String(data.total_tax)));
      $('#total_amount_with_tax').val(formatCommaValue(String(data.total_amount_with_tax)));
      const amount_data = data;

      // 原価の税率計算
      return ajaxWithLoading({
        type: 'GET',
        url: ROOT_PATH + './tax_rates/calc_total',
        dataType: 'json',
        data: {
          rows: cost_rows,
          date: $(tax_date_selector).val()
        }
      }).done(function(data) {
        // 粗利額、粗利率
        if (set_gross_margin) { set_gross_margin(amount_data, data); }
        return formatInputValues();
      }).fail(data => alert('error'));
    }).fail(data => alert('error'));
  }

  static getSelectedIds(table_selector, statuses, field) {
    return $(table_selector).find('input.select-checkbox[type=checkbox]:enabled:visible:checked')
                            .filter(function() { return statuses.indexOf($(this).data('status')) !== -1; })
                            .map(function() { return $(this).data(field); })
                            .get();
  }

  static getSelectedWithAttributes(table_selector, statuses, id_field, fields) {
    return $(table_selector).find('input.select-checkbox[type=checkbox]:enabled:visible:checked')
                            .filter(function() { return statuses.indexOf($(this).data('status')) !== -1; })
                            .map(function() { 
                               const that = this;
                               const attributes = fields.map(field => [field, $(that).data(field)]);
                               return { [$(this).data(id_field)]: Object.fromEntries(attributes) };
                             })
                            .get()
                            .reduce((a, b) => $.extend(a, b));
  }
}

App.CalcCommon = class {
  static prevMonth(month) {
    const date = new Date(month);
    return formatDate(new Date(date.getFullYear(), date.getMonth() - 1, 1), "yyyy/MM");
  }

  static nextMonth(month) {
    const date = new Date(month);
    return formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 1), "yyyy/MM");
  }
}

// 注意: bs5.1においてネストしたモーダルを開くためのクラス。ボタン押下ではなくjs操作でモーダルを開くようにしている
App.NestedModal = class {
  constructor (singular, open_button_id, close_button_id, modal_id) {
    this.modal_id = modal_id
    $(document).on('click', open_button_id, App.ModalCommon.onClickModalOpen);
    $(document).on('click', close_button_id, this.onClickClose.bind(this));
    new App.SearchModalBase(singular);
  }

  onClickClose (event) {
    console.log(this.modal_id)
    $(this.modal_id).modal('hide');
  }
}

App.ModalCommon = class {
  static onClickModalOpen (event) {
    $($(event.target).data('bs-target')).modal('show', $(event.target));
  }

  static onClickCloseConfirm (modal_id) {
    return (event) => {
      const message = warnLeavingUnsaved();
      if (message) {
        if (confirm(message)) {
          $('.modal-body input').removeData('changed');
          $(modal_id).modal('hide');
        }
      } else {
        $('.modal-body input').removeData('changed');
        $(modal_id).modal('hide');
      }
    }
  }
}

App.TableSearchModalBase = class extends App.SearchModalBase {
  constructor (singular) {
    super(singular);
  }

  onShow(event) {
    this.relatedTarget = $(event.relatedTarget)
    $(`#${this.modal_id}`).data('listener', this.relatedTarget.data('listener'));
    const that = this;
    return $.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'html',
      data:
        this.relatedTarget.data('conditions')
    }).done(data => $(`#${that.form_id}`).html(data)).fail(data => alert('error'));
  }

  onClickSelectButton (event) {
    if (!$(`#${this.form_id}`).find("tr.selected").length) {
      alert(I18n.common.message.unselected);
      return;
    }
    const selected_row = $(`#${this.form_id}`).find("tr.selected");
    const listener = $(`#${this.modal_id}`).data('listener');
    this.relatedTarget.parents('tr').find(listener).trigger(this.event_name, normalizeHash(selected_row.data()));
    $(`#${this.modal_id}`).modal('hide');
  }

  onDblClickList (event) {
    const select_data = normalizeHash($(event.currentTarget).data());
    const listener = $(`#${this.modal_id}`).data('listener');
    this.relatedTarget.parents('tr').find(listener).trigger(this.event_name, select_data);
    $(`#${this.modal_id}`).modal('hide');
  }
}

App.Draggable = class {
  constructor (url) {
    $('.droppable').droppable({
      drop: (event, ui) => {
        $.ajax({
          type: 'PUT',
          url: url,
          dataType: 'script',
          data: {
            drop_target_id: $(event.target).data('id'),
            drag_target_id: ui.helper.data('id'),
          }
        })
        .done()
        .fail(() => alert('error'))
      }
    });
    $('.draggable').draggable({ revert: true, containment: 'tbody', revertDuration: 0, zIndex: 100 });
  }
}
