//
// エディットでの値の変更のチェックのため、変更前の値を保持する
// 例)
//  onBlur: (obj) ->
//    if $(obj).hasModify()
//      foo()
$(document).on('focus.common', 'input,textarea,select', function(e) {
  $(this).storageValue();
});

//
// 入力途中で画面を遷移しようとした場合の警告の表示
//

$(document).on('submit.common', 'form', e => $(UPDATE_CHECK_TARGET_ELEMS).removeData('changed'));

$(document).on('change.common', UPDATE_CHECK_TARGET_ELEMS, function(e) {
  $(this).data('changed', 'changed');
});

$(document).on('datepicker:select.common', UPDATE_CHECK_TARGET_ELEMS, function(e) {
  $(this).data('changed', 'changed');
});

$(window).on('beforeunload.common', function(evt){
  if ($('.modal:visible').length) {
    return I18n.common.message.warn_leaving_unsaved;
  }
  return warnLeavingUnsaved();
});

$(document).on('page:before-change.common turbolinks:before-visit.common', function() {
  const message = warnLeavingUnsaved();
  if (message) { return confirm(message); }
});

//
// カンマ編集
//
const INPUT_NUMBER_FOCUS = 'input_focus';

// 整数のカンマ除去
$(document).on('focus.common', '.input-number,.input-number-hankaku', function(e) {
  replaceComma(this);
});

$(document).on('page:load.common turbolinks:load.common', function() {
  formatInputValues();
  // 有効な最初のテキストボックスorコンボボックスにフォーカスを遷移させる
  const object = $(event.target).find('input[type=text]:not([readonly]):not([disabled]):not(.datepicker), select:not([readonly]):not([disabled])').first();
  if (object && object.is(':visible')) {
    object.focus();
  }
});

$(document).on('submit.common', 'form:not(.form-search)', e => removeCommaInputValues());

$(document).on('ajax:before.common', 'form:not(.form-search)', e => removeCommaInputValues());

$(document).on('ajax:complete.common', 'form:not(.form-search)', e => formatInputValues());

// 整数のカンマ回復
// 通常の blur イベント後に typeahead の処理でカンマが除去されるため typeahead:idle も拾っている
$(document).on('blur.common typeahead:idle.common', '.input-number', function(e) {
  if ($(this).data(INPUT_NUMBER_FOCUS)) {
    const value = $(this).val();
    // マイナス、非数は、0とみなす
    if (value !== '') {
      const input_value = parseInt(value);
      if (isNaN(input_value)) {
        $(this).val(0);
      } else {
        $(this).val(value);
        recoverComma(this);
      }
    }
  }
});

// 上記の全角数字を半角数字として受け入れる実装
$(document).on('blur.common typeahead:idle.common', '.input-number-hankaku', function(e) {
  if ($(this).data(INPUT_NUMBER_FOCUS)) {
    let value = $(this).val();
    value = convertZenkaku2HankakuNumber(value);
    // マイナス、非数は、0とみなす
    if (value !== '') {
      const input_value = parseInt(value);
      if (isNaN(input_value)) {
        $(this).val(0);
      } else {
        $(this).val(value);
        recoverComma(this);
      }
    }
  }
});

// 小数のカンマ除去
$(document).on('focus.common', '.input-decimal, .suppress-decimal', function(e) {
  replaceComma(this);
});

// 小数のカンマ回復
// 通常の blur イベント後に typeahead の処理でカンマが除去されるため typeahead:idle も拾っている
$(document).on('blur.common typeahead:idle.common', '.input-decimal', function(e) {
  if ($(this).data(INPUT_NUMBER_FOCUS)) {
    const value = $(this).val();
    const precision = $(this).data('precision');
    // マイナス、非数は、0とみなす
    if (value !== '') {
      const input_value = parseFloat(value);
      if (isNaN(input_value)) {
        $(this).val(0);
      } else {
        $(this).val(value);
        recoverComma2(this, precision);
      }
    }
  }
});

// 小数のカンマ回復とゼロサプレス
$(document).on('blur.common typeahead:idle.common', '.suppress-decimal', function(e) {
  recoverCommaForSuppressDecimal($(this));
}); 

$(document).on('blur.common', '.input-kana', e => $(e.target).val(convertString2Kana($(e.target).val())));

//
// Table
//

// 選択可能テーブル クリック
$(document).on('click.common', '.table-selectable tr', function() {
  const selected_rows = [this];
  if (window.FixedMidashi) {
    selected_rows.push(FixedMidashi.getSourceElement(this));
    selected_rows.push(FixedMidashi.getFixedElements(this));
  }
  for (let selected_row of selected_rows) {
    if (selected_row) {
      $(selected_row).siblings('tr.selected').removeClass('selected');
      $(selected_row).addClass('selected');
    }
  }
});


// 選択可能テーブル マウスリーブ
$(document).on('mouseleave.common', '.table-selectable tr', function() {
  const selected_rows = [this];
  if (window.FixedMidashi) {
    selected_rows.push(FixedMidashi.getSourceElement(this));
    selected_rows.push(FixedMidashi.getFixedElements(this));
  }
  for (let selected_row of selected_rows) {
    if (selected_row) {
      $(selected_row).removeClass('hover');
    }
  }
});


// 選択可能テーブル マウスオーバー
$(document).on('mouseenter.common', '.table-selectable tr', function() {
  const selected_rows = [this];
  if (window.FixedMidashi) {
    selected_rows.push(FixedMidashi.getSourceElement(this));
    selected_rows.push(FixedMidashi.getFixedElements(this));
  }
  for (let selected_row of selected_rows) {
    if (selected_row) {
      $(selected_row).addClass('hover');
    }
  }
});


// 編集ボタン押下イベントハンドラ
$(document).on('click.common', '#table-edit', function(event){
  const messsage = $(event.currentTarget).data('message');
  return emurateButtonClick('.btn-edit', null, messsage);
});

$(document).on('dblclick.common', '.table-selectable tr', function(event){
  let selected_row = this;
  // 固定列のイベントの場合、元となった列に入れ替える
  if (window.FixedMidashi && FixedMidashi.isFixedElement(selected_row)) {
    selected_row = FixedMidashi.getSourceElement(selected_row);
  }
  return emurateButtonClick('.btn-edit', $(selected_row));
});

// 削除ボタン押下イベントハンドラ(IDに対する処理は一旦削除しないと自動的には消えない時がある)
$(document).on('click.common', '#table-delete', function(event){
  const messsage = $(event.currentTarget).data('message');
  return emurateButtonClick('.btn-delete', null, messsage);
});

// チェックボックス選択
$(document).on('click.common', 'input.select-all-checkbox[type=checkbox]', function(event){
  // 固定列の場合は処理しない(再度イベントが来る)
  if (window.FixedMidashi && FixedMidashi.isFixedElement(event.target)) {
    return;
  }

  const selectAll = $(event.target);
  const selectItem = selectAll.parents('table').find('input.select-checkbox[type=checkbox]').filter(':enabled');
  const elem_count = selectItem.filter(':enabled').filter(':not(:hidden)').length;
  const checked_count = selectItem.filter(':enabled').filter(':not(:hidden)').filter(':checked').length;
  const checkAll = elem_count && (elem_count === checked_count);

  // 全体のチェックボックスの状態の更新
  $(selectAll).prop('checked', !checkAll);
  // 固定列のチェックボックスにもチェックの状態を反映する
  if (window.FixedMidashi) {
    FixedMidashi.syncValue(event.target);
  }

  // 各行のチェックボックスの更新
  selectItem.each(function() {
    $(this).prop('checked', !checkAll);
    // 固定列のチェックボックスにもチェックの状態を反映する
    if (window.FixedMidashi) {
      FixedMidashi.syncValue(this);
    }
  });
});

$(document).on('click.common', 'input.select-checkbox[type=checkbox]', function(event){
  // チェックボックスの列が固定されることには対応してない
  // IE の場合のみ動作が異なるなど、対応が難しい
  const selectAll = $(event.target).parents('table').find('input.select-all-checkbox[type=checkbox]');
  const selectItem = $(event.target).parents('table').find('input.select-checkbox[type=checkbox]');
  const elem_count = selectItem.filter(':enabled').filter(':not(:hidden)').length;
  const checked_count = selectItem.filter(':enabled').filter(':not(:hidden)').filter(':checked').length;
  const checkAll = elem_count && (elem_count === checked_count);

  // 全体のチェックボックスの状態の更新
  selectAll.prop('checked', checkAll);
  if (window.FixedMidashi) {
    FixedMidashi.syncValue(selectAll[0]);
  }
});

//
// ポップアップウィンドウ - フォーカス
//
$(document).on('shown.common', '.modal', function(event, options){
  // 有効な最初のテキストボックスorコンボボックスにフォーカスを遷移させる
  const object = $(event.target).find('input[type=text]:not([readonly]):not([disabled]):not(.datepicker), select:not([readonly]):not([disabled]):visible').first();
  if (object && object.is(':visible')) {
    object.focus();
  }
});

//
// エディットでの Enter キー押下での Tab キー押下と同じ動きにする
// 単純に Tab キーと同等ではなく、可視のinputにのみ移動させる
//
$(document).on('keypress.common', 'input[type!=submit][type!=hidden],select', function(e) {
  if ($(this).data('ignore-move')) {
    return true;
  }
  const c = e.which ? e.which : e.keyCode;
  if (c === 13) {
    const elements = 'input[type!=submit][type!=hidden][tabindex!=-1]:visible:not(:disabled):not([readonly]),select[tabindex!=-1]:visible:not(:disabled):not([readonly]),button[tabindex!=-1]:visible:not(:disabled)';
    const index = $(elements).index(this);
    const selector = e.shiftKey ? ':lt(' + index + '):last' : ':gt(' + index + '):first';
    $(elements).filter(selector).focus();
    e.preventDefault();
  }
  return true;
});

//
// ファイル選択
//

// ファイル選択ボタン押下イベントハンドラ
$(document).on('click.common', '.btn-select-file', function(e) {
  const id = $(this).data('fileId');
  $(`input[id=${id}]`).click();
  return false;
});

// ファイル選択ダイアログ-選択イベントハンドラ
$(document).on('change.common', 'input[type=file]', function(e) {
  const file = $(this).prop('files')[0];
  if (!file) {
    return false;
  }
  const id = $(this).data('fileNameId');
  $(`#${id}`).val(file.name);
  // 変更フラグを立てる
  $('input.form-validation').first().trigger('change');

  // 削除ボタン
  const file_control = $(this).parents('.file-control');
  const remove_btn = file_control.find('.btn-remove-file');
  remove_btn.prop('disabled', false);

  // 全画面ボタン
  const fullscreen_btn = file_control.find('.btn-fullscreen-file');
  fullscreen_btn.prop('disabled', false);

  // サムネイル
  const thumbnail_id = $(this).data('thumbnailId');
  if (!thumbnail_id) { return false; }

  // 画像表示
  if (file.type.match('image.*')) {
    let reader = new FileReader;
    reader.onload = function() {
      const img_src = $('<img>').attr('src', reader.result).attr('class', 'thumbnail');
      $(`#${thumbnail_id}`).html(img_src);
      return $(`#${thumbnail_id}`).parent().find('.no-image').addClass('d-none');
    };
    reader.readAsDataURL(file);
  }

  // PDF表示
  if (file.type.match('application/pdf')) {
    const object = $(this).parent().children('object').get(0);
    let reader = new FileReader;
    reader.onload = function() {
      const source = $('<iframe>').addClass('thumbnail')
                            .attr('src', reader.result)
                            .attr('allow', 'fullscreen');
      $(`#${thumbnail_id}`).html(source);
      return $(`#${thumbnail_id}`).parent().find('.no-image').addClass('d-none');
    };
    reader.readAsDataURL(file);
  }

  // 動画表示
  if (file.type.match('video.*')) {
    var URL = URL || webkitURL;
    const source = $('<video>').addClass('thumbnail')
                         .attr('controls', '');
    source[0].src = URL.createObjectURL(file);
    $(`#${thumbnail_id}`).html(source);
    $(`#${thumbnail_id}`).parent().find('.no-image').addClass('d-none');
  }

  return false;
});

// ファイル表示ボタン押下イベントハンドラ
$(document).on('click.common', 'button.btn-show-file', function(e) {
  const download_url = $(this).data('fileUrl');
  App.Sessions.downloadWithWait(download_url, 'processState', disableAllControl, enableAllControl);
  return false;
});

// ファイル削除ボタン押下イベントハンドラ
$(document).on('click.common', 'button.btn-remove-file', function(e) {
  const fileId = $(this).data('fileId');
  const fileNameId = $(this).data('fileNameId');
  const thumbnail_id = $(this).data('thumbnailId');
  $(`#${fileId}`).val('');
  $(`#${fileNameId}`).val('');
  // 変更フラグを立てる
  $('input.form-validation').first().trigger('change');
  // 削除ボタン
  const file_control = $(this).parents('.file-control');
  const remove_btn = file_control.find('.btn-remove-file');
  remove_btn.prop('disabled', true);
  // 全画面ボタン
  const fullscreen_btn = file_control.find('.btn-fullscreen-file');
  fullscreen_btn.prop('disabled', true);
  // サムネイル
  if (thumbnail_id) {
    $(`#${thumbnail_id}`).parent().find('.no-image').removeClass('d-none');
    $(`#${thumbnail_id}`).html('');
  }
  return false;
});

// ファイル全画面表示ボタン押下イベントハンドラ
$(document).on('click.common', 'button.btn-fullscreen-file', function(e) {
  const thumbnail_id = $(this).data('thumbnailId');
  if (thumbnail_id) {
    const thumbnail = $(`#${thumbnail_id}`).children();
    if (thumbnail.length > 0) {
      thumbnail[0].requestFullscreen();
    }
  }
  return false;
});


// #
// # X-Editable
// #
// # 編集画面でのダブルクリックイベントをブロック
// $(document).on 'dblclick', '.editable-container', (e) ->
//   e.preventDefault()
//   e.stopPropagation()
//   false

// クリアボタンの動作
$(document).on('click.common', '#clear-button', function(e) {
  const form = $(e.currentTarget).parents('form');
  form.find('input[type=text]').each(function() {
    $(this).val($(this).data('default') || '');
  });
  form.find('select').each(function() {
    $(this).val($(this).data('default') || '');
  });
  return form.find('input[type=checkbox]').each(function() {
    $(this).prop('checked', $(this).data('default') || false);
  });
});

//
// Nested Form
//
// 既に jquery_nested_form によって登録済みなので解除してからネームスペース付きで登録
$(document).off('click', 'form a.add_nested_fields');
$(document).off('click', 'form a.remove_nested_fields');
$(document).on('click.common', 'form a.add_nested_fields', nestedFormEvents.addFields);
$(document).on('click.common', 'form a.remove_nested_fields', nestedFormEvents.removeFields);

$(document).on('nested:fieldAdded.common nested:fieldRemoved.common', 'form', e => // 変更フラグを立てる
$('input.form-validation').first().trigger('change'));

//
// collapse の開閉時のアイコンの切替
// <p>
//   <a data-toggle='collapse' href='#additional_item'>
//     <i class='fa fa-chevron-circle-right fa-lg' aria-hidden='true'></i>
//   </a>
// </p>
// <div id='additional_item' class='collapse'>
//   xxxxx
// </div>
//
$(document).on('show.bs.collapse', '.collapse', function(e) {
  $(`[data-toggle='collapse'][href='#${event.currentTarget.id}'] i`)
    .removeClass('fa-chevron-circle-right')
    .addClass('fa-chevron-circle-down');
});
$(document).on('hide.bs.collapse', '.collapse', function(e) {
  $(`[data-toggle='collapse'][href='#${event.currentTarget.id}'] i`)
    .removeClass('fa-chevron-circle-down')
    .addClass('fa-chevron-circle-right');
});


// inputのpattern属性を利用したバリデーションにおけるエラーメッセージ(マウスオーバーしたときにメッセージを表示させたくない場合)
$(document).on('blur', '.pattern-validate', function() {
  if(this.validity.patternMismatch) {
    this.setCustomValidity($(this).data('error-message'));
  } else {
    this.setCustomValidity('');
  }
  this.reportValidity();
});
