window.ModalBase = class ModalBase {
  // コンストラクタ
  //  table tableのID
  //  form formのID
  //  field_prefix 一覧に設定されているinputのIDのプレフィックス
  constructor(table, form, field_prefix, options = {}) {
    this.initOperationButton = this.initOperationButton.bind(this);
    this.fields = this.fields.bind(this);
    this.clearError = this.clearError.bind(this);
    this.clearData = this.clearData.bind(this);
    this.checkData = this.checkData.bind(this);
    this.getListValue = this.getListValue.bind(this);
    this.setDetailData = this.setDetailData.bind(this);
    this.onAddDetail = this.onAddDetail.bind(this);
    this.onUpdateDetail = this.onUpdateDetail.bind(this);
    this.reflectInputData = this.reflectInputData.bind(this);
    this.table = table;
    this.form = form;
    this.field_prefix = field_prefix;

    this.modeAdd = true;
    this.update_handler = null;
    this.add_row = options['add-row'] || '#add-row';

    $(document).on('click', `#${this.form} #btn-register`, (event) => {
      if (this.modeAdd) {
        this.onAddDetail(event);
      } else {
        this.onUpdateDetail(event);
      }
    });
    // ダイアログ表示ボタン押下イベントハンドラ
    $(document).on('click', '.btn-show-dialog', (event) => {
      this.clearData(event);
      this.setDetailData($(event.currentTarget).data('row-index'));
    });
    // 行追加処理完了イベントハンドラ
    $(document).on(`nested:fieldAdded:${this.table}`, (event) => {
      const add_row = $(event.field);
      this.reflectInputData(add_row);
    });
    // 行削除イベントハンドラ
    $(document).on(`nested:fieldRemoved:${this.table}`, (event) => {
      if (this.update_handler) {
        this.update_handler();
      }
    });
  }

  initOperationButton(table, add_button, edit_button, delete_button) {
    // 追加ボタン押下イベントハンドラ
    $(document).on('click', add_button, this.clearData);
    // 編集ボタン押下イベントハンドラ
    $(document).on('click', edit_button, (event) => {
      emurateButtonClick('.btn-show-dialog', null, null, table);
    });
    $(document).on('dblclick', `${table} tr`, (event) => {
      emurateButtonClick('.btn-show-dialog', $(event.currentTarget));
    });
    // 削除ボタン押下イベントハンドラ
    $(document).on('click', delete_button, (event) => {
      emurateButtonClick('.btn-delete', null, null, table);
    });
  }

  // フィールド(と初期値)の一覧を返す
  fields() {
    alert('abstruct!');
  }

  // エラー解除
  clearError() {
    if ($(`#${this.form}`).length) {
      $(`#${this.form}`).parsley().reset();
    }
  }

  // 入力値のクリア
  clearData(obj) {
    this.modeAdd = true;
    this.clearError();
    const object = this.fields();
    for (let field in object) {
      // typeahead が有効な場合、 tt-input がclassに指定されているはず
      const value = object[field];
      if ($(`#${this.form} #${field}`).hasClass('tt-input')) {
        $(`#${this.form} #${field}`).typeahead('val', value);
      } else {
        $(`#${this.form} #${field}`).val(value);
      }
    }
    return false;
  }

  // 入力値のチェック
  checkData() {
    // カンマを抜く
    removeCommaInputValues();
    const ret = $(`#${this.form}`).parsley().validate();
    if (!ret) {
      formatInputValues();
    }
    return ret;
  }

  // 一覧から値を取得
  getListValue(row_index, field) {
    return $(`#${this.field_prefix}_${row_index}_${field}`).val();
  }

  // ダイアログに値の設定
  setDetailData(row_index) {
    this.modeAdd = false;
    for (let field in this.fields()) {
      let value = this.getListValue(row_index, field);
      // typeahead が有効な場合、 tt-input がclassに指定されているはず
      if ($(`#${this.form} #${field}`).hasClass('tt-input')) {
        $(`#${this.form} #${field}`).typeahead('val', value);
      } else {
        if ($(`#${this.form} #${field}`).hasClass('input-number')) {
          value = formatCommaValue(value || '');
        } else if ($(`#${this.form} #${field}`).hasClass('input-decimal')) {
          value = formatCommaValue2(value || '');
        }
        $(`#${this.form} #${field}`).val(value);
      }
    }
    $(`#${this.form} #row_index`).val(row_index);
  }

  // 一覧への追加
  onAddDetail(obj) {
    if (this.checkData()) {
      // 行追加(その他処理は追加のイベントハンドラで実施する)
      $(`${this.add_row}`).click();
    }
  }

  // 更新ボタン押下
  onUpdateDetail(obj) {
    if (this.checkData()) {
      const row_index = $(`#${this.form} #row_index`).val();
      const row = $(`#${this.table}`).find(`[data-row-index=${row_index}]`).parents('tr');
      this.reflectInputData(row);
    }
  }

  // 入力値を一覧に設定
  reflectInputData(row) {
    // 編集ボタンに付与されているrow-indexを取得
    const row_index = row.find('.btn-show-dialog').data('row-index');

    const object = this.fields();
    for (let field in object) {
      let value = object[field];
      value = $(`#${this.form} #${field}`).val();
      // hidden に設定
      $(`#${this.field_prefix}_${row_index}_${field}`).val(value);

      // 一覧に設定
      // コンボボックスの場合は、表示されている文字を設定する
      if ($(`#${this.form} #${field}`).prop('tagName') && ($(`#${this.form} #${field}`).prop('tagName').toLowerCase() === 'select')) {
        value = $(`#${this.form} #${field}`).find('option:selected').text();
      }
      if (row.find(`td.${field}`).hasClass('delimited')) {
        value = formatCommaValue(value || '');
      } else if (row.find(`td.${field}`).hasClass('delimited-decimal')) {
        value = formatCommaValue2(value || '');
      }
      row.find(`td.${field}`).text(value);
    }

    // 変更フラグを立てる
    $('input.form-validation').first().trigger('change');

    // 閉じる
    $('.btn-close').click();

    if (this.update_handler) { return this.update_handler(); }
  }
};


// 
// 検索ダイアログ基本クラス
// 
App.SearchModalBase = class SearchModalBase {

  // コンストラクタ
  //  singular モデル名(単数形のスネークケース)
  //  plural   テーブル名(複数形のスネークケース)
  constructor(singular, plural = null, overwrite = {}) {
    this.onShow = this.onShow.bind(this);
    this.onClickSelectButton = this.onClickSelectButton.bind(this);
    this.onDblClickList = this.onDblClickList.bind(this);
    this.triggerSelectEvent = this.triggerSelectEvent.bind(this);
    this.onHide = this.onHide.bind(this);

    if (!plural) {
      plural = singular + 's';
    }
    this.modal_id = `search${snakeToCamel(singular, true)}Modal`;
    this.form_id = `search_${plural}_form`;
    this.event_name = `${singular}:select`;
    this.url = `${ROOT_PATH}./${plural}/search`;

    for (let key in overwrite) {
      const value = overwrite[key];
      this[key] = value;
    }

    $(document).off(`.${this.modal_id}`);
    $(`#${this.modal_id}`).on(`show.bs.modal`, this.onShow);
    $(document).on(`click.${this.modal_id}`, `#${this.modal_id} .btn-select`, this.onClickSelectButton);
    $(document).on(`dblclick.${this.modal_id}`, `#${this.modal_id} .table-selectable tr`, this.onDblClickList);
    $(document).on(`hidden.${this.modal_id}`, `#${this.modal_id}`, this.onHide);
  }

  onShow(event) {
    $(`#${this.modal_id}`).data('listener', $(event.relatedTarget).data('listener'));
    const that = this;
    return $.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'html',
      data:
        $(event.relatedTarget).data('conditions')
    }).done(data => $(`#${that.form_id}`).html(data)).fail(data => alert('error'));
  }

  onClickSelectButton(event) {
    if (!$(`#${this.form_id}`).find('tr.selected').length) {
      alert(I18n.common.message.unselected);
      return;
    }
    this.triggerSelectEvent($(`#${this.form_id}`).find('tr.selected'));
  }


  onDblClickList(event) {
    this.triggerSelectEvent($(event.currentTarget));
  }


  triggerSelectEvent(selected_row) {
    const listener = $(`#${this.modal_id}`).data('listener');
    const select_data = normalizeHash(selected_row.data());
    $(listener).trigger(this.event_name, select_data);
    // 閉じる
    $(`#${this.modal_id}`).modal('hide');
  }


  onHide(event, options) {
     $(`#${this.modal_id}`).find(`#${this.form_id}`).empty();
  }
};
