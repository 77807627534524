//
// 入力途中で画面を遷移しようとした場合の警告の表示
//

window.warnLeavingUnsaved = function() {
  let warn = false;
  $(':focus').blur();
  $(UPDATE_CHECK_TARGET_ELEMS).each(function() {
    if ($(this).data('changed')) {
      warn = true;
      return false;
    }
  });
  if (warn) {
    return I18n.common.message.warn_leaving_unsaved;
  }
};

// 変更フラグのクリア
window.clearChanged = () => $(UPDATE_CHECK_TARGET_ELEMS).removeData('changed');

//
// カンマ編集
//
const INPUT_NUMBER_FOCUS = 'input_focus';

// カンマを除去
window.replaceComma = function(txtObj) {
  $(txtObj).val(removeCommaValue($(txtObj).val()));
  $(txtObj).select();
  $(txtObj).data(INPUT_NUMBER_FOCUS, INPUT_NUMBER_FOCUS);
};

// カンマを回復
window.recoverComma = function(txtObj) {
  const general_value = $(txtObj).val();
  if (/^\d*/.test(general_value)) {
    $(txtObj).val(accounting.formatNumber(removeCommaValue(general_value)));
    if ($(txtObj).typeahead) {
      $(txtObj).typeahead('val', accounting.formatNumber(removeCommaValue(general_value)));
    }
  }
  $(txtObj).removeData(INPUT_NUMBER_FOCUS);
};

// カンマを回復
window.recoverComma2 = function(txtObj, precision = 2) {
  const ret = this.formatCommaValue2($(txtObj).val(), precision);
  $(txtObj).val(ret);
};

// カンマを回復(ゼロサプレスの値に回復)
window.recoverCommaForSuppressDecimal = function(txtObj) {
  const input_value = txtObj.val().replace(/[^0-9.]/g, '');
  const suppressed_number = input_value.match(/\./) ? input_value.replace(/0*$/, '').replace(/\.$/, '') : input_value;
  const decimal_match = suppressed_number.match(/\..*/);
  const precision = decimal_match ? decimal_match[0].length - 1 : 0;
  txtObj.val(this.formatCommaValue2(suppressed_number, precision));
};

// カンマを除去
window.removeCommaValue = text => text.replace(/\,/g,'');

// カンマ区切り
window.formatCommaValue = function(text) {
  if (/^-?\d*$/.test(text)) {
    text = accounting.formatNumber(removeCommaValue(text));
  }
  return text;
};

// カンマ区切り
window.formatCommaValue2 = function(text, precision = 2) {
  let ret = (0).toFixed(precision);
  if (text.split('.').length < 3) {
    ret = accounting.formatNumber(removeCommaValue(text), { precision });
  }
  return ret;
};

window.convertZenkaku2HankakuNumber = function(num) {
  const z = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];
  for (let i = 0, end = z.length-1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
    num = num.replace(new RegExp(z[i], 'g'), i);
  }
  return num;
};

window.convertString2Kana = function(string) {
  const array = string.split('').map(function(char) {
    const char_code = char.charCodeAt(0);
    if (((char_code >= 12353) && (char_code <= 12435)) || (char_code === 12445) || (char_code === 12446)) {
      return String.fromCharCode(char_code + 96);
    } else {
      return char;
    }
  });
  return array.join('');
};


window.parseIntegerValue = function(text) {
  // 文字列型の場合、カンマを除去
  if ($.type(text) === 'string') { text = removeCommaValue(text); } 
  if (isNaN(text) || (text === '')) {
    return 0;
  } else {
    return parseInt(text, 10);
  }
};

window.parseFloatValue = function(text) {
  // 文字列型の場合、カンマを除去
  if ($.type(text) === 'string') { text = removeCommaValue(text); } 
  if (isNaN(text) || (text === '')) {
    return 0;
  } else {
    return parseFloat(text);
  }
};

// INPUT の要素をclassで指定されたフォーマットに変換する
// input-number => カンマ区切り
// input-decimal => カンマ区切り+小数点第2位まで表示
window.formatInputValues = function() {
  $('input[type=text].input-number').each(function() {
    if ($(this).val() !== '') {
      recoverComma($(this));
    }
  });
  $('input[type=text].input-number-hankaku').each(function() {
    if ($(this).val() !== '') {
      recoverComma($(this));
    }
  });
  $('input[type=text].input-decimal').each(function() {
    if ($(this).val() !== '') {
      const precision = $(this).data('precision');
      recoverComma2($(this), precision);
    }
  });
  return $('input[type=text].suppress-decimal').each(function() {
    if ($(this).val() !== '') {
      recoverCommaForSuppressDecimal($(this));
    }
  });
};

window.removeCommaInputValues = function() {
  $('input[type=text].input-number').each(function() {
    if ($(this).val() !== '') {
      $(this).val(parseInt(removeCommaValue($(this).val())));
    }
  });
  $('input[type=text].input-number-hankaku').each(function() {
    if ($(this).val() !== '') {
      $(this).val(parseInt(removeCommaValue($(this).val())));
    }
  });
  $('input[type=text].input-decimal').each(function() {
    if ($(this).val() !== '') {
      $(this).val(parseFloat(removeCommaValue($(this).val())));
    }
  });
  return $('input[type=text].suppress-decimal').each(function() {
    if ($(this).val() !== '') {
      $(this).val(parseFloat(removeCommaValue($(this).val())));
    }
  });
};

//
// フォーマット
//

const ERA_DATA = [
  { date: new Date(2019, 5-1, 1), alphaName: 'R' },
  { date: new Date(1989, 1-1, 8), alphaName: 'H' },
  { date: new Date(1926,12-1,25), alphaName: 'S' },
  { date: new Date(1912, 7-1,30), alphaName: 'T' },
  { date: new Date(1868, 1-1,25), alphaName: 'M' },
];

// 日付をフォーマットして返す
// 例 'yyyy-MM-dd hh:mm:ss.SSS'
// formatDate(new Date(2019,5-1, 1), 'gee/MM/dd')
// => 'R01/05/01'
window.formatDate = function(date, format = 'yyyy/MM/dd') {
  const era = function(date) {
    for (let era_data of ERA_DATA) {
      const era_date = era_data.date;
      if (era_data.date <= date) {
        const year = (date.getFullYear() - era_date.getFullYear()) + 1;
        const ret = {
          alphaName: era_data.alphaName,
          year
        };
        return ret;
      }
    }
  };
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/ee/g, ('0' + era(date).year).slice(-2));
  if (format.match(/S/g)) {
    const milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    const length = format.match(/S/g);
    for (let i = 0, end = length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
  }
  format = format.replace(/g/g, era(date).alphaName);
  return format;
};

// 以下のフォーマットに対応
// 2019/05/01
// 2019/05
// 20190501
// R01/05/01
// R01/05
// R010501
window.parseDate = function(object) {
  let d, m, y;
  if ((typeof object !== 'string') || (object.length === 0)) {
    return null;
  }
  let add_year = 0;
  for (let era_data of ERA_DATA) {
    const era_date = era_data.date;
    if (object[0] === era_data.alphaName) {
      add_year = era_date.getFullYear() - 1;
      break;
    }
  }
  // 西暦の場合
  if (add_year === 0) {
    if (object.length === 7) {
      object += '/01';
    }
    if (new RegExp(/^[0-9]{8}$/).test(object)) {
      y = object.substr(0, 4);
      m = object.substr(4, 2);
      d = object.substr(6, 2);
      object = y + '/' + m + '/' + d;
    }
    return new Date(object);
  }
  // 和暦の場合
  object = object.substr(1);
  if (object.length === 5) {
    object += '/01';
  }
  if (new RegExp(/^[0-9]{6}$/).test(object)) {
    y = object.substr(0, 2);
    m = object.substr(2, 2);
    d = object.substr(4, 2);
    object = y + '/' + m + '/' + d;
  }
  const dates = object.split('/');
  return new Date(parseInt(dates[0]) + add_year, dates[1] - 1, dates[2]);
};

// 日付を'MM/dd'形式にフォーマットする
window.formatSimpleDate = function(object) {
  if ((typeof object === 'string') && (object.length === 0)) {
    return '';
  }
  const date = new Date(object);
  const mm = ('0'+(date.getMonth()+1)).slice(-2);
  const dd = ('0'+date.getDate()).slice(-2);
  return mm + '/' + dd;
};

// 指定した秒数経過したDateオブジェクトを取得する
window.calcAddSecDateTime = function(object, addSec) {
  const date = new Date(object);
  const resultSec = date.getTime() + addSec;
  date.setTime(resultSec);
  return date;
};

//
// Table
//

window.emurateButtonClick = function(btn_selector, selected_row, messsage, table_selector = '.table-selectable') {
  if (!selected_row) {
    selected_row = $(table_selector).find('tr.selected:visible');
  }
  if (!selected_row.length) {
    alert(messsage || I18n.common.message.unselected);
    return;
  }
  const edit_button = selected_row.find(btn_selector);
  if (edit_button.length) {
    edit_button[0].click();
  }
};

window.disableAllControl = () => {
 $(OPERATABLE_TARGET_ELEMS).each(function() {
    if ($(this).is(':disabled')) {
      $(this).data('disabled', 'disabled');
    } else if ($(this).is('a')) {
      $(this).attr('disabled', true);
    } else {
      $(this).prop('disabled', true);
    }
  });
};

window.enableAllControl = () => {
  $(OPERATABLE_TARGET_ELEMS).each(function() {
    if ($(this).data('disabled')) {
      $(this).removeData('disabled');
    } else if ($(this).is('a')) {
      $(this).attr('disabled', false);
    } else {
      $(this).prop('disabled', false);
    }
  });
};

//
// 読み取り専用にする
// data に ignore-readonly がついている場合は、対象としない
// 例) data: { 'ignore-readonly': true }
//
window.doReadonlyAllControl = () => {
  $(OPERATABLE_TARGET_ELEMS).each(function() {
    // continue
    if ($(this).data('ignore-readonly')) {
      return true;
    }

    if ($(this).is('a')) {
      $(this).attr('disabled', true);
    // input[type=text] の場合は、readonly にしたいが、datepickerが出てきてしまうので disabled にする
    } else if ($(this).is('input[type=text]:not(.datepicker,.monthpicker),textarea')) {
      $(this).prop('readonly', true);
    } else {
      $(this).prop('disabled', true);
    }
  });
};

window.isIOS = function() {
  const agent = navigator.userAgent;
  if ((agent.search(/iPhone/) !== -1) || (agent.search(/iPad/) !== -1) || (agent.search(/iPod/) !== -1)) {
    return true;
  } else {
    return false;
  }
};

window.flashMessage = function(name, msg) {
  removeMessage();
  if (name === 'notice') {
    name = 'success';
  } else {
    name = 'danger';
  }
  return $("main[role = 'main']").prepend(`<div class='alert alert-${name}'><button class='btn-close float-end' aria-hidden='true' type='button' data-bs-dismiss='alert'></button><div id="flash_notice">${msg}</div></div>`);
};

window.removeMessage = () => $("main[role = 'main']").children('.alert').remove();

// 文字数制限
// textarea等、maxlengthが利用できない場合に代用する
window.limitCheck = function(obj, limit, ignoreCRLF = false) {
  // 改行が存在する場合、DB上は2文字(CRLF)で保存されるため、ここでも2文字としてカウントする
  if ((ignoreCRLF && ($(obj).val().replace(/\s+/g,'').length > limit)) ||
     (!ignoreCRLF && ($(obj).val().replace(/\r?\n/g, '__').length > limit))) {
    // 日本語入力の場合、最後に編集中だった文字列ですべての文字列を上書きしてしまうため
    // 一度フォーカスを外して合わせ直す処理を入れる
    $(obj).blur();
    $(obj).val($(obj).prevValue());
    $(obj).focus();
  } else {
    $(obj).storageValue();
  }
};

// スネークケースをキャメルケースにする
window.snakeToCamel = function(p, headToUpperCase = false) {
  // _+小文字を大文字にする(例:_a を A)
  p = p.replace(/_./g, s => s.charAt(1).toUpperCase());
  if (headToUpperCase) {
    p = p.charAt(0).toUpperCase() + p.slice(1);
  }
  return p;
};

// キャメルケースをスネークケースにする
window.camelToSnake = p => {
  //大文字を_+小文字にする(例:A を _a)
  return p.replace(/([A-Z])/g, s => '_' + s.charAt(0).toLowerCase());
};

window.toDasherize = str => {
  return str.replace(/_/g, '-');
};

// ハッシュのキーをスネークケースに変換する
window.normalizeHash = function(src) {
  const dst = {};
  for (let key in src) {
    const value = src[key];
    dst[camelToSnake(key)] = value;
  }
  return dst;
};

window.updateSelectAllCheckboxStatus = function(selector) {
  const selectAll = $(selector).find('input.select-all-checkbox[type=checkbox]');
  const selectItem = $(selector).find('input.select-checkbox[type=checkbox]');
  const elem_count = selectItem.filter(':enabled').filter(':not(:hidden)').length;
  const checked_count = selectItem.filter(':enabled').filter(':not(:hidden)').filter(':checked').length;
  const checkAll = elem_count && (elem_count === checked_count);
  selectAll.prop('checked', checkAll);
};

//
// Loading
//
window.showLoading = function() {
  if ($('#loading').length === 0) {
    $('body').append(`<div id='loading'><img src='${LOADING_IMAGE}' class='loading-image'><div class='loading-message'>${I18n.common.message.loading}</div></div>`);
  }
  const count = ($('#loading').data('loading-count') || 0) + 1;
  $('#loading').data('loading-count', count);
};
 
window.hideLoading = function() {
  const count = ($('#loading').data('loading-count') || 1) - 1;
  $('#loading').data('loading-count', count);
  if (count < 1) {
    $('#loading').remove();
  }
};

window.ajaxWithLoading = function(arg) {
  const opt = $.extend({
    beforeSend(jqXHR, settings) {
      showLoading();
    }
  }, $.ajaxSettings, arg);
  const jqXHR = $.ajax(opt);
  const defer = $.Deferred();
  jqXHR.done(function(data, statusText, jqXHR) {
    defer.resolveWith(this, arguments);
  });
  jqXHR.fail(function(jqXHR, statusText, errorThrown) {
    defer.rejectWith(this, arguments);
  });
  jqXHR.always(function() {
    hideLoading();
  });
  return $.extend({}, jqXHR, defer.promise());
};


window.isValidDate = function(s) {
  const matches = /^(\d+)\/(\d+)\/(\d+)$/.exec(s);
  if (!matches) {
    return false;
  }
  const year = parseInt(matches[1]);
  const month = parseInt(matches[2]);
  const day = parseInt(matches[3]);
  if ((month < 1) || (month > 12)) {
    return false;
  } 
  if ((day < 1) || (day > 31)) {
    return false;
  }
  const date = new Date(year, month - 1, day);
  if ((date.getFullYear() !== year) || (date.getMonth() !== (month - 1)) || (date.getDate() !== day)) {
    return false;
  }
  return true;
};
