jQuery.fn.extend({
  storageValue() {
    $(this).data('prev_value', $(this).val());
    return this;
  },
  hasModify() {
    return this.prevValue() !== $(this).val();
  },
  prevValue() {
    return $(this).data('prev_value');
  },

  addDataValue(data_name, data_value, separator = ',') {
    const prev_value = $(this).data(data_name);
    if (prev_value) {
      const values = prev_value.replace(/^\s+|\s+$/g, '').split(separator);
      if (!($.inArray(data_value, values) > -1)) {
        values.push(data_value);
      }
      $(this).data(data_name, values.join(separator));
    } else {
      $(this).data(data_name, data_value);
    }
  },
  removeDataValue(data_name, data_value, separator = ',') {
    const prev_value = $(this).data(data_name);
    if (prev_value) {
      const values = prev_value.replace(/^\s+|\s+$/g, "").split(separator);
      const index = $.inArray(data_value, values);
      if (index > -1) {
        values.splice(index, 1);
      }
      $(this).data(data_name, values.join(separator));
    }
  }
});